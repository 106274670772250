module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:100,200,300,400,500,600,700,800","sans-serif","Paytone One"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KF6NFKVHDG"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quita Simples – Simplificando o pagamento dos seus precatórios","short_name":"Quita Simples","description":"Compramos seu Precatório, sem mais esperar, seu pagamento aqui é à vista em 24hrs!.","start_url":"/","background_color":"var(--color-background)","theme_color":"var(--color-primary)","icon":"src/images/icon.png","lang":"pt-br","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1bfc8842065e482c73e647088b40cbef"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
